<template lang="pug">
div.logs-tab
  v-tabs(fixed-tabs v-model="tab" small).log-tab-main
    v-tab(@click="loadAllPages" small) All
    v-tab(@click="loadReserve" small) Reserve
    v-tab(@click="loadContact" small) Contact

  //- v-tabs(v-model="tabStatus" centered small hide-slider v-show="showingStatus").log-tab-secondary
  //-   v-tab(small @click="resetStatus") All statuses
  //-   v-tab(small @click="loadImportantStatuses") important

</template>

<script>
import {LOG_STATUS, LOG_PAGE} from "@/util/const"
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    loadData: {
      type: Function,
      required: true
    },
    getDefaultPaginationParams: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    tab: null,
    tabStatus: null,
    showingStatus: true
  }),

  methods: {
    loadAllPages() {
      this.model.params.page = [LOG_PAGE.ACCEPTANCE, LOG_PAGE.RESERVATIONS, LOG_PAGE.CONTANCT]
      this.model.params.status = []
      this._loadDataHandler()
      this.showingStatus = true
    },

    _loadDataHandler() {
      this.model.items = []
      this.loadData({...this.getDefaultPaginationParams()})
    },

    loadReserve() {
      this.model.params.page = [LOG_PAGE.RESERVATIONS]
      this.model.params.status = [LOG_STATUS.SUCCESS, LOG_STATUS.ERROR]
      this._loadDataHandler()
      this.showingStatus = true
    },

    loadContact() {
      this.model.params.page = [LOG_PAGE.CONTANCT]
      this.model.params.status = []
      this._loadDataHandler()
      this.showingStatus = false
    },

    resetStatus() {
      delete this.model.params.status
      this._loadDataHandler()
    },

    loadImportantStatuses() {
      this.model.params.status = [LOG_STATUS.SUCCESS, LOG_STATUS.ERROR]
      this._loadDataHandler()
    },
  }
}
</script>

<style lang="scss">
.logs-tab .v-tabs .v-item-group{
  background-color: $log-primary;
}

.log-tab-main.v-tabs .v-item-group .v-tab.v-tab {
  color: #fff;
}

.log-tab-secondary.v-tabs .v-item-group .v-tab.v-tab {
  color: $log-default;
  &.v-tab--active {
    color: $primary-color;
  }
} 
</style>